import React, { Fragment, useState, useEffect } from "react"
import API from "../../common/api.js"

import { FormattedMessage, injectIntl } from "react-intl"
import { FreeButton, PurchaseButton } from "@src/components/buttons"
import { PhotoshopRequired, PurchaseRow, ButtonsColumn } from "./style"
import { On, Row, CenterColumn, WhiteLink } from "@src/style/globalStyle"
import { StyledMessage, StyledMessages } from "@components/common/styledMessage"
import Linker from "@src/common/linker"
import { localizedUrl } from "@src/components/link"

const InactiveCodeComponent = ({ codeInactive }) => {
  if (codeInactive) {
    return (
      <div>
        <FormattedMessage id="buy_buttons.inactive_code" />
      </div>
    )
  } else {
    return <div />
  }
}

export default injectIntl(({ intl }) => {
  const { locale } = intl
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState({
    apiReady: false,
  })

  const intlExtras = {
    m1: (...msg) => (
      <WhiteLink
        key={msg}
        href="#"
        onClick={() => {
          Linker.openUrl(localizedUrl("/blog/2019-08-30-faq/", locale))
        }}
      >
        {msg}
      </WhiteLink>
    ),
  }

  const loadingCallback = ({ loading }) => {
    setLoading(loading)
  }

  const componentDidMount = () => {
    const urlCode = API.getParameterByName("code")

    if (urlCode === null || urlCode === "") {
      setData({
        code: urlCode,
        loading: false,
        apiReady: true,
      })
      return
    }

    API.pricing(urlCode, loadingCallback)
      .then((response) => {
        const result = {
          apiReady: true,
          loading: false,
          fullPrice: response.fullPrice.price,
          code: urlCode,
        }
        if (response.discountPrice) {
          result.discountPrice = response.discountPrice.price
          result.codeInactive = response.codeInactive
        }
        setData(result)
      })
      .catch((error) => {
        console.error(error)
      })
  }

  useEffect(componentDidMount, []) // componentDidMount

  return (
    <Fragment>
      {loading || !data.apiReady ? (
        <FormattedMessage id="buy_buttons.fetching_discounts" />
      ) : (
        <PurchaseRow>
          <On tablet laptop>
            <CenterColumn>
              <>
                {data.code != null && data.code !== "" && (
                  <Row>
                    {data.discountPrice !== 0 && <FreeButton />}
                    <PurchaseButton
                      discountPrice={data.discountPrice}
                      fullPrice={data.fullPrice}
                    />
                  </Row>
                )}
                {(data.code == null || data.code == "") && <FreeButton />}
                <PhotoshopRequired>
                  <StyledMessages
                    id="buy_buttons.photoshop_required"
                    values={intlExtras}
                  />
                </PhotoshopRequired>
              </>
            </CenterColumn>
          </On>
          <On mobile>
            <ButtonsColumn>
              {data.discountPrice !== 0 && <FreeButton />}
              {data.code != null && data.code !== "" && (
                <PurchaseButton
                  discountPrice={data.discountPrice}
                  fullPrice={data.fullPrice}
                />
              )}
              <PhotoshopRequired>
                <StyledMessages
                  id="buy_buttons.photoshop_required"
                  values={intlExtras}
                />
              </PhotoshopRequired>
            </ButtonsColumn>
          </On>
        </PurchaseRow>
      )}
    </Fragment>
  )
})
