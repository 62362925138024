import React from "react"
import styled from "styled-components"
import { FormattedMessage, injectIntl } from "react-intl"
import config from "@src/common/config"
import { trackCustomEvent } from "gatsby-plugin-google-analytics"
import {
  MediumLargeBlueButton,
  MediumLargeYellowButton,
  YellowButton,
  LongYellowButton,
  SmallBlueButton,
  SmallYellowButton,
} from "./style"
import * as GlobalStyle from "@src/style/globalStyle"

import { ModalSignInContext } from "@components/main/context"

const getDiscountPercentage = (discountPrice, fullPrice) => {
  return Math.round((1 - discountPrice / fullPrice) * 100)
}

export const FreeButton = injectIntl(
  ({ intl: { locale }, small, noAdobe, ...props }) => {
    const { v3 } = props
    const Button = small ? SmallBlueButton : MediumLargeBlueButton
    return (
      <GlobalStyle.CenterColumn>
        <Button
          onClick={() => {
            config.mailerlite.showFreeForm(locale, trackCustomEvent)
          }}
        >
          <FormattedMessage id="buy_buttons.try_for_free" />
          {v3 ? <></> : <FormattedMessage id="buy_buttons.arcpanel" />}
        </Button>
        {!noAdobe && (
          <a
            href="https://exchange.adobe.com/creativecloud/plugindetails.html/app/cc/6a68f34c?utm_source=homepage&utm_medium=website&utm_campaign=arcpanel_homepage"
            target="_blank"
          >
            <FormattedMessage id="buy_buttons.install" />
          </a>
        )}
      </GlobalStyle.CenterColumn>
    )
  }
)

export const SimplePurchaseButton = ({ small }) => {
  const Button = small ? SmallYellowButton : MediumLargeYellowButton
  return (
    <ModalSignInContext.Consumer>
      {({ setShowSignIn }) => {
        return (
          <Button
            onClick={() => {
              setShowSignIn(true)
            }}
          >
            <FormattedMessage id="buy_buttons.purchase_now" />
          </Button>
        )
      }}
    </ModalSignInContext.Consumer>
  )
}

export const PurchaseButton = ({ fullPrice, discountPrice }) => {
  const SplittedYellowButton = styled(YellowButton)`
    flex-direction: row;
    justify-content: space-between;
    padding: 0px;
  `
  const Left = styled.div`
    width: 167px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  `
  const Purchase = styled.span`
    font-weight: 700;
  `
  const Discount = styled.span`
    ${(props) => props.theme.typography.paragraph.XS}
    line-height: 1.4rem;
    font-size: 0.9rem;
  `
  const Right = styled.div`
    width: 69px;
    height: 69px;
    font-weight: 700;
    border-left: 1px solid rgba(255, 255, 255, 0.28);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  `

  if (discountPrice === 0) {
    return (
      <ModalSignInContext.Consumer>
        {({ setShowSignIn }) => {
          return (
            <LongYellowButton
              onClick={() => {
                setShowSignIn(true)
              }}
            >
              <FormattedMessage id="buy_buttons.for_you_free" />
            </LongYellowButton>
          )
        }}
      </ModalSignInContext.Consumer>
    )
  }

  return (
    <ModalSignInContext.Consumer>
      {({ setShowSignIn }) => {
        return (
          <SplittedYellowButton
            onClick={() => {
              setShowSignIn(true)
            }}
          >
            <Left>
              <Purchase>
                {discountPrice === 0 && (
                  <FormattedMessage id="buy_buttons.for_you_free" />
                )}
                {discountPrice !== 0 && (
                  <FormattedMessage id="buy_buttons.purchase_now" />
                )}
              </Purchase>
              {discountPrice && discountPrice < fullPrice && (
                <Discount>
                  <FormattedMessage
                    id="buy_buttons.discount.fullprice"
                    values={{
                      fullPrice: fullPrice,
                    }}
                  />
                  <FormattedMessage
                    id="buy_buttons.discount.discount"
                    values={{
                      discount: getDiscountPercentage(discountPrice, fullPrice),
                    }}
                  />
                </Discount>
              )}
            </Left>
            <Right>
              {discountPrice && (
                <FormattedMessage
                  id="buy_buttons.price_short"
                  values={{ price: discountPrice }}
                />
              )}
              {!discountPrice && (
                <FormattedMessage
                  id="buy_buttons.price_short"
                  values={{ price: fullPrice }}
                />
              )}
            </Right>
          </SplittedYellowButton>
        )
      }}
    </ModalSignInContext.Consumer>
  )
}
