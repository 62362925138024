import React from 'react'
import styled, { css } from 'styled-components'

import { CenterColumn } from '@src/style/globalStyle'
import device from '@src/style/device'

export const PurchaseRow = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 2rem;
  width: 100%;
  max-width: 350px;
  min-width: 250px;
  margin: 0 auto;

  @media ${device.tablet} {
    max-width: 450px;
    min-width: 400px;
  }
`

export const ButtonsColumn = styled(CenterColumn)`
  button {
    margin-top: 1rem;
  }
`

export const PhotoshopRequired = styled.div`
  ${props => props.theme.typography.paragraph.XXS}
  text-shadow: #000 1px 0 10px;
`
